import React, { Component } from "react";
import Navbar from "./Navbr";
import Footer from "./lfooter";
import features_dmsintegration from "../../../Images/features-dms-integration.jpg";
import features_dragdrop from "../../../Images/features-drag-drop.jpg";
import features_cusomization from "../../../Images/features-cusomization.jpg";
import features_conditional from "../../../Images/features-conditional-logic.jpg";
import Multi_StepForms from "../../../Images/Multi-StepForms.jpg";
import Real_TimeDataAnalytics from "../../../Images/Real-TimeDataAnalytics.jpg";
import AdvancedFormFieldOptions from "../../../Images/AdvancedFormFieldOptions.jpg";
import AutomatedEmailNotifications from "../../../Images/AutomatedEmailNotifications.jpg";
import ocr from "../../../Images/ocr.jpg";
import formembeddingsharing from "../../../Images/formembeddingandsharing.jpg";
import useraccesscontrol from "../../../Images/user-access-control.jpg";
import fileuploadcapability from "../../../Images/fileuploadcapability.jpg";
import FileUploadCapability from "../../../Images/File Upload Capability.png";
import CustomizableThemes from "../../../Images/customthemebranding.jpg";
import APIsforThirdpartyintegration from "../../../Images/api.jpg";
import cms from "../../../Images/cms.jpg";
import functionalwebsite from "../../../Images/build-functional-website.jpg";
import formanalyticsdashboard from "../../../Images/form-analytics-dashboard.jpg";
import dataexport from "../../../Images/data-export.webp";
export default class Feature extends Component {
  render() {
    return (
      <div>
        <Navbar />

        <section
          id="feature"
          className="services sections-bg"
          style={{ backgroundColor: "white" }}
        >
          <div className="container p-6">
            <div className="sec-title text-center">
              <h2>Features</h2>
            </div>
            <div className="row gy-4" data-aos-delay="100">
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={features_dragdrop}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text"> Drag-and-Drop Form Builder</h3>
                  <p className="text">
                    Create and customize forms easily using an intuitive
                    drag-and-drop interface—no coding skills required.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={cms}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text"> Content Management System (CMS) </h3>
                  <p className="text">
                    Easily create your CMS software within minutes, all without
                    coding experience.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={features_cusomization}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text"> Customizable Templates</h3>
                  <p className="text">
                    Choose from a variety of pre-designed templates tailored to
                    specific business needs, saving time on form creation.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={functionalwebsite}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">
                    {" "}
                    Build Your Functional Website & Application
                  </h3>
                  <p className="text">
                    Design and launch fully functional websites and applications
                    effortlessly using the platform.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={features_conditional}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Conditional Logic</h3>
                  <p className="text">
                    Enhance form interactivity by setting up rules to show or
                    hide fields, send notifications, or trigger actions based on
                    user responses.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={Multi_StepForms}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text"> Multi-Step Forms</h3>
                  <p className="text">
                    {" "}
                    Improve user experience and completion rates by breaking
                    down lengthy forms into multiple steps or sections.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={Real_TimeDataAnalytics}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Real-Time Data Analytics</h3>
                  <p className="text">
                    Monitor and analyze form submissions in real time,
                    generating reports and visualizing data for deeper insights.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={AdvancedFormFieldOptions}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Advanced Form Field Options</h3>
                  <p className="text">
                    Use a variety of field types, including text boxes,
                    dropdowns, checkboxes, file uploads, and signatures to
                    collect diverse data inputs.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={AutomatedEmailNotifications}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Automated Email Notifications</h3>
                  <p className="text">
                    Set up automatic email notifications upon form submission,
                    ensuring you and your team receive instant updates
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={formembeddingsharing}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Form Embedding & Sharing</h3>
                  <p className="text">
                    {" "}
                    Easily embed forms into websites or blogs, or share them via
                    direct links, email, or social media to reach your audience.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={fileuploadcapability}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">File Upload Capability</h3>
                  <p className="text">
                    {" "}
                    Allow users to upload documents, images, or other files
                    directly through your forms, simplifying data collection.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={ocr}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Optical Character Recognition (OCR)</h3>
                  <p className="text">
                    {" "}
                    Streamline your data entry process by automating data
                    extraction with OCR, reducing the need for manual
                    transcription and minimizing errors.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={CustomizableThemes}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Customizable Themes & Branding</h3>
                  <p className="text">
                    Match forms with your company branding by customizing
                    colors, fonts, and logos for a consistent look and feel.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={ocr}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">UTM Enabled Shared Form</h3>
                  <p className="text">
                    {" "}
                    Track form traffic sources and performance with UTM
                    parameters.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={APIsforThirdpartyintegration}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">APIs for Third-Party Integration</h3>
                  <p className="text">
                    Seamlessly connect GigaForms with third-party applications
                    to streamline workflows and data management.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={useraccesscontrol}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">User Access Control</h3>
                  <p className="text">
                    Manage permissions and access levels for your team, ensuring
                    only authorized users can edit forms or view submissions.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={formanalyticsdashboard}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text">Form Analytics Dashboard</h3>
                  <p className="text">
                    Access a comprehensive dashboard to track form performance,
                    including key metrics like submission rates, user behavior,
                    and drop-off points.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="service-item  position-relative">
                  <div className="icon">
                    <img
                      src={dataexport}
                      alt="img"
                      style={{
                        height: "250px",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <h3 className="text"> Data Export Options</h3>
                  <p className="text">
                    Export form data in various formats, such as CSV, Excel, and
                    PDF, for easy integration and reporting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
