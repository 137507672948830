import React, { useState, useEffect } from "react";
import User from "../../../Images/User.png"; // Correct path to the image

const testimonials = [
  {
    name: "Amit Khanna",
    image: User,
    quote:
      "GigaForms has completely changed how we collect customer feedback. The forms are incredibly easy to build, and the customization options make them look so professional on our website. The best part? The real-time analytics help us make data-driven decisions quickly!",
    designation: "Marketing Lead at <b>BrightTech Solutions</b>",
  },
  {
    name: "Sarah Mitchell",
    image: User,
    quote:
      "We needed a simple yet powerful solution for event registrations, and GigaForms delivered! The drag-and-drop builder made it so easy to set up, and the automated notifications ensured no attendee was left out. It's a must-have tool for any business handling large-scale events.",
    designation: "Event Manager at <b>Summit Planners</b>",
  },
  {
    name: "Rahul Verma",
    image: User,
    quote:
      "As a small e-commerce business, finding an affordable yet feature-rich form builder was challenging until we discovered GigaForms. The seamless payment integrations and UTM tracking have boosted our conversions significantly!",
    designation: "Founder of <b>UrbanShop India</b>",
  },
  {
    name: "Dr. Neha Kapoor",
    image: User,
    quote:
      "Managing patient intake used to be a nightmare for our clinic. With GigaForms, we now collect patient information securely before appointments, eliminating long wait times and paperwork.",
    designation: "Chief Medical Officer at <b>CareWell Clinics</b>",
  },
  {
    name: "Susant Pujari",
    image: User,
    quote:
      "We switched to GigaForms from another provider, and the difference is night and day! The ability to create multi-step forms and automate approvals has saved us hours of manual work. Their support team is also incredibly responsive!",
    designation: "Trustee at <b>AIHD</b>",
  },
  {
    name: "Rajendra Rout",
    image: User,
    quote:
      "I love how user-friendly GigaForms is! I set up a job application form for my company in minutes without any coding experience. Plus, the form analytics dashboard helped us optimize our hiring process. Highly recommended!",
    designation: "Proprietor EasyAgroTech",
  },
];

const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(3);

  useEffect(() => {
    // Adjust visible slides based on screen size
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setVisibleSlides(1);
      } else if (window.innerWidth < 992) {
        setVisibleSlides(2);
      } else {
        setVisibleSlides(3);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  // Logic to handle the sliding effect for visible slides
  const getVisibleSlides = () => {
    const totalSlides = testimonials.length;
    let slides = [];

    for (let i = 0; i < visibleSlides; i++) {
      slides.push(testimonials[(currentIndex + i) % totalSlides]);
    }

    return slides;
  };

  return (
    <div className="carousel-container">
      <div className="carousel">
        {getVisibleSlides().map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <img src={testimonial.image} alt={testimonial.name} />
            <p className="testimonial-name">{testimonial.name}</p>
            <p
              className="testimonial-designation"
              dangerouslySetInnerHTML={{ __html: testimonial.designation }}
            />
            <blockquote className="testimonial-quote">
              "{testimonial.quote}"
            </blockquote>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button className="prev" onClick={prevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={nextSlide}>
        &#10095;
      </button>

      {/* Style for the carousel */}
      <style>
        {`
          .carousel-container {
            position: relative;
            max-width: 1200px;
            margin: auto;
            overflow: hidden;
            padding: 20px;
          }
          .carousel {
            display: flex;
            gap: 20px;
            transition: transform 0.5s ease-in-out;
          }
          .testimonial-card {
            flex: 1;
            min-width: 350px;
            background: #F5F6F8;
            padding: 20px 15px;
            padding-top:30px;
            border-radius: 10px;
            text-align: center;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
          .testimonial-card img {
            border-radius: 50%;
            width: 80px;
            height: 80px;
            margin-bottom: 10px;
          }
          .testimonial-name {
            font-weight: bold;
          }
          .testimonial-designation {
            font-size: 14px;
            color: gray;
          }
          .testimonial-quote {
            margin-top: 10px;
          }
          .prev, .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: #FA6519;
            color: white;
            border: none;
            padding: 10px;
            font-size: 24px;
            cursor: pointer;
            border-radius: 50%;
          }
          .prev { left: -40px; }
          .next { right: -40px; }
          .prev:hover, .next:hover {
            background: #d55414;
          }
          @media (max-width: 768px) {
            .testimonial-card {
              min-width: 100%;
            }
            .prev { left: 10px; }
            .next { right: 10px; }
          }
        `}
      </style>
    </div>
  );
};

export default TestimonialCarousel;
