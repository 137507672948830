import React, { Component } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { FIELDS, UNOIQUE_TYPE, VALIDATORS } from '../../../lib/constants';
import { checkDataSourceFieldType, checkFormData, checkHiddenField, convertNoToArray, getHiddenDeleteField, splittingComma } from '../../../lib/utils';
import OcrRemoveModal from '../../common/components/OcrRemoveModal';
import { table_field_types } from '../FieldTypes';

export default class EditFormModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            label: "",
            placeholder: "",
            mandatory: false,
            validationType: null,
            validationMessage: null,
            validationExpression: null,
            check_unique: false,
            auto_sum: false,
            unique_type: 1,
            type: '',
            master_data_code: 'string',
            advance_datasource: [
                {
                    datasource_template: this.props.list?.advance_datasource_detail?.[0]?.datasource_template || null,
                    filter: this.props.list?.advance_datasource_detail?.[0]?.filter || null,
                    value: this.props.list?.advance_datasource_detail?.[0]?.value || null,
                    response: this.props.list?.advance_datasource_detail?.[0]?.response || null,
                }
            ],
            higher_value: '',
            lower_value: '',
            send_alert: false,
            api: '',
            values: "",
            currentDatasource: "DataSource",
            currentWidth: 12,
            table_data: {},
            no_of_headers: 0,
            body: [],
            headers: [],
            showModal: false,
            currentIndex: 0,
            ocr_view: false,
            showHeader: false,
            showDeleteModal: false,
            display_in_main_view: false,
            filters: [],
            initial: this.props.list?.initial || "True", 

            no_of_options: 0, // Track the number of options
      options: [], 
        }
    }

    handleAddClick = () => {
        this.setState(prevState => {
            const { advance_datasource, filters } = prevState;
            // Get the current advance_datasource entry
            const currentDatasourceTemplate = advance_datasource[0].datasource_template;
            const currentResponse = advance_datasource[0].response;
            // const currentFilter = this.state.advance_datasource?.map(data => data.filter);
            // const currentValue = this.state.advance_datasource?.map(data => data.value);
            // Add a new filter with the current datasource_template and response
            const newFilter = {
                datasource_template: currentDatasourceTemplate,
                filter: null, // Initially null, will be set by the dropdown
                response: currentResponse,
                value: null // Initially null, will be set by the dropdown
            };

            // Update the advance_datasource array to include the new filter values
            const updatedAdvanceDatasource = [
                ...advance_datasource,
                newFilter
            ];

            return {
                advance_datasource: updatedAdvanceDatasource,
                filters: [...filters, newFilter] // Keep the filters array updated if needed
            };
        });
    };

    handleFilterChange = (index, field, value) => {
        this.setState(prevState => {
            const newFilters = [...prevState.filters];
            newFilters[index][field] = value; // Update the specific field in the filter
            return { filters: newFilters };
        });
    };


    componentDidMount = () => {
        
        if (this.props.list?.table_data !== '' && this.props.list?.type == "multiple_choice") {
           
            var tableData = JSON.parse(this.props.list?.table_data)
            console.log(tableData,'tableData')
            this.setState({ table_data: {
                options: tableData,
               
              },
              no_of_options:tableData?.length,
              options: tableData,
            })
        }else{
            this.setState({ table_data: {
                options: [
                    { type: "text", name: "Option 1", value: "Option 1", selected: false },
                    { type: "text", name: "Option 2", value: "Option 2", selected: false },
                    { type: "text", name: "Option 3", value: "Option 3", selected: false },
                    { type: "text", name: "Option 4", value: "Option 4", selected: false },
                  ],
                
              },
              no_of_options:4,
              options: [
                { type: "text", name: "Option 1", value: "Option 1", selected: false },
                { type: "text", name: "Option 2", value: "Option 2", selected: false },
                { type: "text", name: "Option 3", value: "Option 3", selected: false },
                { type: "text", name: "Option 4", value: "Option 4", selected: false },
              ],
            })
        }
   

        if (this.props.list?.table_data !== '') {
            var tableData = JSON.parse(this.props.list?.table_data)
            this.setState({ table_data: tableData, no_of_headers: tableData.headers?.length, headers: tableData.headers, body: tableData.body })
        }
        else {
            this.setState({ table_data: { headers: [{ type: "text", name: "header1", api: "" }, { type: "text", name: "header2", api: "" }, { type: "text", name: "header3", api: "" }], body: [] }, no_of_headers: 3, headers: [{ type: "text", name: "header1", api: "" }, { type: "text", name: "header2", api: "" }, { type: "text", name: "header3", api: "" }], body: [] })
        }

        this.setState({ ...this.props.list, master_data_code: this.props.list.master_data_code ? this.props.list?.master_data_code : 'string', currentWidth: this.props.list.style ? this.props.list.style : '12', validationType: this.props.list?.validation.type, validationMessage: this.props.list?.validation.error_message, validationExpression: this.props.list?.validation.expression })

        if (this.props.formData.count > 0) {
            this.setState({ showHeader: checkFormData(this.props.formData.data[0].data, this.props.list?.id) })
        }
        // const initialTemplate = this.props.list?.advance_datasource_detail[0]?.datasource_template;
        // if (initialTemplate && initialTemplate !== 'string') {
        //     this.props.activeField(initialTemplate);
        // }
        if (this.props.list?.advance_datasource_detail?.length > 0) {
            // Use index 0 for advance_datasource and exclude it from filters
            const initialDatasource = {
                datasource_template: this.props.list?.advance_datasource_detail[0].datasource_template || null,
                filter: this.props.list?.advance_datasource_detail[0].filter || null,
                value: this.props.list?.advance_datasource_detail[0].value || null,
                response: this.props.list?.advance_datasource_detail[0].response || null
            };

            // Exclude the first item (index 0) for filters
            const filters = this.props.list?.advance_datasource_detail.slice(1).map(data => ({
                datasource_template: data.datasource_template,
                filter: data.filter,
                response: data.response,
                value: data.value
            }));

            // Update both advance_datasource and filters states
            this.setState({
                advance_datasource: [initialDatasource],
                filters
            });
        }



    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.list?.id !== prevProps.list.id) {
            if (this.props.list?.table_data !== '' && this.props.list?.type == "multiple_choice") {
                
                var tableData = JSON.parse(this.props.list?.table_data)
                console.log(tableData,'tableData')
                this.setState({ table_data: {
                    options: tableData,
                    
                  },
                  no_of_options:tableData?.length,
                  options: tableData,
                })
            }
            // else{
            //     this.setState({ table_data: {
            //         options: tableData,
            //         selectedOptions: [], // Store the selected options
            //       },
            //       no_of_options:tableData?.length,
            //       options: tableData,
            //     })
            // }

            if (this.props.list?.table_data !== '') {
                var tableData = JSON.parse(this.props?.list?.table_data)
                this.setState({ table_data: tableData, no_of_headers: tableData?.headers?.length, headers: tableData?.headers, body: tableData?.body })
            }
            else {
                this.setState({ table_data: { headers: [{ type: "text", name: "header1", api: "" }, { type: "text", name: "header2", api: "" }, { type: "text", name: "header3", api: "" }], body: [] }, no_of_headers: 3, headers: [{ type: "text", name: "header1", api: "" }, { type: "text", name: "header2", api: "" }, { type: "text", name: "header3", api: "" }], body: [] })
            }
            this.setState({ ...this.props.list, master_data_code: this.props.list?.master_data_code ? this.props.list?.master_data_code : 'string', currentWidth: this.props.list.style ? this.props.list.style : '12', validationType: this.props.list?.validation.type, validationMessage: this.props.list?.validation.error_message, validationExpression: this.props.list?.validation.expression })
        }
        if (prevProps.formData.count !== this.props.formData.count) {
            if (this.props.formData.count > 0) {
                this.setState({ showHeader: checkFormData(this.props.formData.data[0].data, this.props.list?.id) })
            }
        }
    }
    ocrAlert() {
        if (this.state.ocr_view == false) {
            this.setState({ showDeleteModal: true })
        }
    }
    onSubmit = () => {
        var my_table_data = {};
        if(this.props.list?.type == "multiple_choice"){
            console.log(1)
            if (this.state.table_data !== '' && typeof (this.state.table_data) == 'string') {
                console.log('working123')
                my_table_data = { ...JSON.parse(this.state.table_data) }
            }
            my_table_data= this.state.options;
        }
       else{
 if (this.state.table_data !== '' && typeof (this.state.table_data) == 'string') {
            my_table_data = { ...JSON.parse(this.state.table_data) }
        }
        my_table_data['headers'] = this.state.headers;
        my_table_data['body'] = this.state.body;
       }


       

        var updatedData = { ...this.props.list }
        var custom_validation = { ...this.props.list?.validation }

        custom_validation.type = this.state.validationType
        custom_validation.expression = this.state.validationExpression
        custom_validation.error_message = this.state.validationMessage
        if (this.state.label) {
            updatedData.label = this.state.label
            updatedData.placeholder = this.state.placeholder
            updatedData.mandatory = this.state.mandatory
            updatedData.display_in_main_view = this.state.display_in_main_view
            updatedData.validation = custom_validation
            updatedData.check_unique = this.state.check_unique
            updatedData.auto_sum = this.state.auto_sum
            updatedData.unique_type = this.state.unique_type
            updatedData.type = this.state.type
            updatedData.master_data_code = this.state.master_data_code
            updatedData.api = this.state.api
            updatedData.values = this.state.values
            updatedData.currentWidth = this.state.currentWidth
            updatedData.ocr_view = this.state.ocr_view
            updatedData.table_data = JSON.stringify(my_table_data)
            updatedData.advance_datasource = this.state.advance_datasource
            updatedData.higher_value = this.state.higher_value
            updatedData.lower_value = this.state.lower_value
            updatedData.send_alert = this.state.send_alert
            updatedData.initial = this.state.initial
        }
        this.props.onSubmit(updatedData)
    }


    handleOptionChange = (index, field, value) => {
        console.log("Handling change for option:", index, field, value); // Debugging line
    
        this.setState((prevState) => {
          const options = prevState.options.map((opt, idx) =>
            idx === index ? { ...opt, [field]: value } : opt
          );
    
          console.log("Updated options state:", options); // Debugging line
          return { options };
        });
      };
    
      handleNoOfOptionsChange = (event) => {
        const no_of_options = parseInt(event.target.value, 10);
      
        this.setState((prevState) => {
          let options = [...prevState.options];
      
          // Add new empty options if needed
          while (options.length < no_of_options) {
            options.push({ type: "text", name: `Option ${options.length + 1}`, value: "",selected:false });
          }
      
          // Remove excess options
          options = options.slice(0, no_of_options);
      
          return { no_of_options, options };
        });
      };

        // Handle deleting an option by index
  handleDeleteOption = (index) => {
    const updatedOptions = this.state.options.filter((_, i) => i !== index);
    this.setState({ options: updatedOptions });
  };


    render() {

        console.log(this.props.list, 'list')
        const { no_of_options, options } = this.state;
        console.log(no_of_options, options,'no_of_options, options')
        return (
            <div className='my-scrollbar' style={{ color: "#000", height: window.innerHeight, color: "#fff", overflowY: "scroll", paddingBottom: 20 }}>
                <div style={{ marginTop: "20%", paddingInline: 20, }}>
                    <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", fontWeight: "bold", fontSize: 18 }}>
                        {`${this.props.list?.type?.toUpperCase()} Properties`}
                        <i className="fa fa-times" aria-hidden="true" onClick={() => this.props.closeModal()}></i>
                    </div>
                </div>


                <h6 className='ms-3 mt-3'>Width</h6>

                <Row className='m-3 p-1' style={{ backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    {[{ 12: "100%" }, { 6: "50%" }, { 4: "30%" }, { 3: "25%" }].map((item, index) => {
                        return (
                            <Col onClick={() => { this.setState({ currentWidth: Object.keys(item)[0] }) }} className='p-2' key={index} md="3" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: this.state.currentWidth == Object.keys(item)[0] ? "#fff" : "#000", backgroundColor: this.state.currentWidth == Object.keys(item)[0] ? "#3e4652" : "#fff", cursor: "pointer", borderRadius: 5 }}>{Object.values(item)[0]}</Col>
                        )
                    })}
                </Row>

                <div className='m-3'>
                    <h6>Field Name</h6>
                    <div>
                        <Form.Control type="text" placeholder={'Enter Label'}
                            defaultValue={this.props.list?.label}
                            onChange={(event) => {
                                this.setState({ label: event.target.value })
                            }} />
                    </div>
                </div>

                {
                    this.props.list?.type == 'table' ?
                        <div>
                            <div className='m-3'>
                                <h6>Enter No of Header Title</h6>
                                <div title='Please Enter any number, Ex: 3'>
                                    <Form.Control type="number" placeholder={'Please Enter any number, Ex: 3'}
                                        value={parseInt(this.state.no_of_headers)}
                                        min={3}
                                        readOnly={this.state.showHeader}
                                        onChange={(event) => {
                                            this.setState({ no_of_headers: event.target.value, headers: convertNoToArray(event.target.value) })
                                            var x = [];
                                            for (var i = 0; i < parseInt(this.state.body.length); i++) {
                                                var arr = []
                                                convertNoToArray(event.target.value).map((item, ind) => {
                                                    if (item.type == 'radio' || item.type == 'checkbox') {
                                                        arr.push(false)
                                                    }
                                                    else {
                                                        arr.push(' ')
                                                    }
                                                })
                                                x.push(arr)
                                            }
                                            this.setState({ body: x })
                                        }} />
                                </div>
                            </div>

                            {
                                this.state.no_of_headers > 0 &&
                                <div className='m-3' >
                                    <h6 style={{ fontWeight: "bold" }}>Heades Type Values</h6>
                                    <div className='mt-1' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div style={{ width: "35%" }}>
                                            <h6>Type</h6>
                                        </div>
                                        <div style={{ width: "35%" }}>
                                            <h6>Name</h6>
                                        </div>
                                        <div style={{ width: "25%" }}>
                                            <h6>Set API</h6>
                                        </div>
                                    </div>
                                    {
                                        this.state.headers.map((item, index) => {
                                            return (
                                                <div key={index} className='mb-2' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div style={{ width: "35%" }}>

                                                        <Form.Select aria-label="Default select example" style={{ fontSize: 15 }}
                                                            defaultValue={this.state.headers[index].type}
                                                            onChange={(event) => {
                                                                var headers = [...this.state.headers]
                                                                headers[index].type = event.target.value
                                                                this.setState({ headers: headers })
                                                            }}
                                                        >
                                                            {table_field_types.map((row, i) => {
                                                                return (
                                                                    <option value={row.type} key={i}>{row.text}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </div>
                                                    <div style={{ width: "35%" }}>
                                                        <Form.Control type="text" placeholder={'Header Name'} style={{ fontSize: 15 }}
                                                            defaultValue={this.state.headers[index].name}
                                                            onChange={(event) => {
                                                                var headers = [...this.state.headers]
                                                                headers[index].name = event.target.value
                                                                this.setState({ headers: headers })
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ width: "25%" }}>
                                                        <Button variant={item.api !== '' ? 'success' : 'primary'} style={{ width: "100%", fontSize: 15 }} onClick={() => { this.setState({ showModal: true, currentIndex: index }) }}>SET</Button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            }

                            <div className='m-3'>
                                <h6>Enter No Rows</h6>
                                <div title='Please Enter any number, Ex: 2'>
                                    <Form.Control type="number" placeholder={'Please Enter any number, Ex: 2'}
                                        value={this.state.body.length}
                                        readOnly={this.state.showHeader}
                                        onChange={(event) => {
                                            var x = [];
                                            for (var i = 0; i < parseInt(event.target.value); i++) {
                                                var arr = []
                                                this.state.headers.map((item, ind) => {
                                                    if (item.type == 'radio' || item.type == 'checkbox') {
                                                        arr.push('false')
                                                    }
                                                    else {
                                                        arr.push(' ')
                                                    }
                                                })
                                                x.push(arr)
                                            }
                                            this.setState({ body: x })
                                        }} />
                                </div>
                            </div>
                            {
                                this.state.body.length > 0 &&
                                <div className='m-3'>
                                    <h6>Enter Column Headers Values</h6>
                                    {

                                        this.state.body.map((item, index) => {
                                            return (
                                                <div title='Please Enter Column Header, Ex: Name' className='mb-2'>
                                                    <Form.Control type="text" placeholder={`Column Header ${index + 1}`}
                                                        defaultValue={item[0]}
                                                        readOnly={this.state.showHeader}
                                                        onChange={(event) => {
                                                            var x = [...this.state.body];
                                                            x[index][0] = event.target.value
                                                            this.setState({ body: x })
                                                        }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            }

                        </div>
                        :this.props.list?.type == 'multiple_choice' ?
                        <div>
  <div className="m-3">
    <h6>Enter No of Options</h6>
    <Form.Control
      type="number"
      placeholder="Enter number of options, Ex: 3"
      value={this.state.no_of_options}
      min={1}
      onChange={this.handleNoOfOptionsChange}
    />
  </div>

  <div className="m-3">
          <h6>Enter Option Field Values</h6>
          {this.state.options?.map((item, index) => (
            <div key={index} className="mb-2 d-flex">
              {/* Input for Option Name */}
              <Form.Control
                type="text"
                placeholder={`Option Name ${index + 1}`}
                value={item.name || ""} // Bind the input value to `name`
                onChange={(event) => this.handleOptionChange(index, "name", event.target.value)} // Handle name change
              />
               <div
               className='d-flex justify-content-center align-items-center m-2'
                onClick={() => this.handleDeleteOption(index)}
              >
                <i class="fa fa-times" aria-hidden="true" style={{fontSize:'20px'}}></i>
              </div>
            </div>
            
          ))}
        </div>
       
</div>
                        :
                        <div>
                            <div className='m-3'>
                                <h6>Placeholder</h6>
                                <div>
                                    <Form.Control type="text" placeholder={'Enter placeholder'}
                                        defaultValue={this.props.list?.placeholder}
                                        onChange={(event) => {
                                            this.setState({ placeholder: event.target.value })
                                        }} />
                                </div>
                            </div>

                            <div className='m-3'>
                                <Row >
                                    <Col md="5">
                                        <h6 > Mandatory </h6>
                                    </Col>
                                    <Col md="1">
                                        <Form.Check type="switch" defaultChecked={this.props.list?.mandatory} onChange={(e) => this.setState({ mandatory: e.target.checked })} />
                                    </Col>
                                </Row>
                            </div>
                            {(this.props.list?.type === 'textbox' || this.props.list?.type === 'text' || this.props.list?.type === 'number') &&
                                <>
                                    <div className='m-3'>
                                        <Row >
                                            <Col md="5">
                                                <h6 > Show Alert </h6>
                                            </Col>
                                            <Col md="1">
                                                <Form.Check type="switch" defaultChecked={this.props.list?.send_alert} onChange={(e) => this.setState({ send_alert: e.target.checked })} />
                                            </Col>
                                        </Row>
                                    </div>
                                    {this.state.send_alert && 
                                     <Row className='m-2'>
                                     <Col md={6} > <div>
                                         <h6>Lower Value</h6>
                                         <div>
                                             <Form.Control type="text" placeholder={'Enter Lower Value'}
                                                 defaultValue={this.props.list?.lower_value}
                                                 onChange={(event) => {
                                                     this.setState({ lower_value: event.target.value })
                                                 }} />
                                         </div>
                                     </div></Col>
                                     <Col md={6} > <div>
                                         <h6>Higher Value</h6>
                                         <div>
                                             <Form.Control type="text" placeholder={'Enter Higher Value'}
                                                 defaultValue={this.props.list?.higher_value}
                                                 onChange={(event) => {
                                                     this.setState({ higher_value: event.target.value })
                                                 }} />
                                         </div>
                                     </div></Col>
                                 </Row>
                                    }
                                   


                                </>
                            }

                            <div className='m-3'>
                                <Row >
                                    <Col md="7">
                                        <h6 > Display In Main View </h6>
                                    </Col>
                                    <Col md="1">
                                        <Form.Check type="switch" defaultChecked={this.props.list?.display_in_main_view} onChange={(e) => this.setState({ display_in_main_view: e.target.checked })} />
                                    </Col>
                                </Row>
                            </div>
                            <div className='m-3'>
                                <h6>Initial</h6>
                                <div>
                                    <Form.Select value={this.state.initial} onChange={(event) => this.setState({ initial: event.target.value })}>
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className='m-3'>
                                <h6>Select Type</h6>
                                <div>
                                    <Form.Select defaultValue={this.props.list?.type} onChange={(event) => this.setState({ type: event.target.value })}>
                                        <option value={null}>-Select Field Type-</option>
                                        {FIELDS.map((row, index) => {
                                            return (
                                                <option value={row.value} key={index}>{row.label}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                            </div>

                            {checkDataSourceFieldType(this.props.list?.type) || checkHiddenField(this.props.list?.type) ? null :
                                <>
                                    {this.props.list?.validation && <div className='m-3'>
                                        <h6>Validators</h6>
                                        <div>
                                            <Form.Select defaultValue={this.props.list?.validation.type} onChange={(event) => this.setState({ validationType: event.target.value })}>
                                                <option value={null}>-Select Validator Type-</option>
                                                {VALIDATORS.map((row, index) => {
                                                    return (
                                                        <option value={row.value} key={index}>{row.label}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </div>
                                    </div>}
                                    {this.state.validationType == null ? null : <div className='m-3'>
                                        <h6>Validation Error Message <span className='text-danger'>*</span></h6>
                                        <div>
                                            <Form.Control type="text" placeholder="Error Message"
                                                required={this.state.validationType !== null}
                                                defaultValue={this.props.list?.validation.error_message}
                                                onChange={event => { this.setState({ validationMessage: event.target.value }); this.setState({ validationExpression: 'na' }) }} />
                                        </div>
                                    </div>}
                                </>}

                            <div className='m-3'>
                                <Row >
                                    <h6 > Check Unique </h6>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Form.Check type="switch" defaultChecked={this.props.list?.check_unique}
                                            onChange={(e) => this.setState({ check_unique: e.target.checked })} />
                                        Is The Field Value Unique ?
                                    </div>
                                </Row>
                            </div>

                            <div className='m-3'>
                                <h6>Unique Type</h6>
                                <div>
                                    <Form.Select defaultValue={this.props.list?.unique_type} onChange={(event) => this.setState({ unique_type: event.target.value })}>
                                        <option value={null}>-Select Unique Type-</option>
                                        {UNOIQUE_TYPE.map((row, index) => {
                                            return (
                                                <option value={row.value} key={index}>{row.label}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </div>
                            </div>

                            <div className='m-3'>
                                <Row >
                                    <h6 >Auto Sum </h6>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Form.Check type="switch" defaultChecked={this.props.list?.auto_sum}
                                            onChange={(e) => this.setState({ auto_sum: e.target.checked })} />
                                        Calculate Sum ?
                                    </div>
                                </Row>
                            </div>
                            <div className='m-3'>
                                {(this.props.list?.type == "file") &&
                                    <Row>
                                        <Form.Label>Need OCR?</Form.Label>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <Form.Check type="switch" defaultChecked={this.props.list?.ocr_view}
                                                onChange={() => {
                                                    this.setState({ ocr_view: !this.state.ocr_view }); setTimeout(() => {
                                                        this.ocrAlert()
                                                    }, 0);
                                                }} />
                                            Need OCR ?
                                        </div>
                                    </Row>
                                }
                            </div>

                            {
                                (this.props.list?.type === 'radio' || this.props.list?.type === 'dropdown' || this.props.list?.type === 'checkbox') &&
                                <div>
                                    <hr />
                                    <h5 className='ms-3 mt-3' style={{ fontSize: 18, fontWeight: "bold", }}>Select Data Source</h5>

                                    <Row className='m-3 p-1' style={{ backgroundColor: "#fff", borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        {['DataSource', 'Api', 'Values', 'Advance'].map((item, index) => {
                                            return (
                                                <Col onClick={() => { this.setState({ currentDatasource: item }) }} className='p-2' key={index} md="3" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: this.state.currentDatasource == item ? "#fff" : "#000", backgroundColor: this.state.currentDatasource == item ? "#3e4652" : "#fff", cursor: "pointer", borderRadius: 5 }}>{item}</Col>
                                            )
                                        })}
                                    </Row>
                                    {
                                        this.state.currentDatasource === 'DataSource' &&
                                        <div className='m-3'>
                                            <h6>Select Data Source</h6>
                                            <div>
                                                <Form.Select defaultValue={this.props.list?.master_data_code} onChange={(event) => this.setState({ master_data_code: event.target.value })}>
                                                    <option value={'string'}>-Select Data Source-</option>
                                                    {this.props.allFormList && this.props.allFormList.data && this.props.allFormList.data.length > 0 && this.props.allFormList.data.map((row, index) => {
                                                        return (
                                                            <option value={row.id} key={index}>{row.label}</option>
                                                        )
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.currentDatasource === 'Api' &&
                                        <div className='m-3'>
                                            <h6>Enter API</h6>
                                            <div>
                                                <Form.Control type="text" placeholder={'Enter API'}
                                                    defaultValue={this.props.list?.api}
                                                    onChange={(event) => {
                                                        this.setState({ api: event.target.value })
                                                    }} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.currentDatasource === 'Values' &&
                                        <div className='m-3'>
                                            <h6 title='Please Enter the values including comma. Ex: male, female, others'>Enter Values</h6>
                                            <div title='Please Enter the values including comma. Ex: male, female, others'>
                                                <Form.Control type="text" as={'textarea'} placeholder={'Please Enter the values including comma. Ex: male, female, others'} defaultValue={this.props.list?.values}
                                                    onChange={(event) => {
                                                        this.setState({ values: event.target.value })
                                                    }} />
                                            </div>
                                        </div>}


                                    {/* add new field for dropdown */}
                                    {
                                        this.state.currentDatasource === 'Advance' &&

                                        <Row>
                                            <Col md={12}>
                                                <div className='m-3'>
                                                    <h6>Select Data Source</h6>
                                                    <Form.Select
                                                        defaultValue={this.props.list?.advance_datasource_detail[0]?.datasource_template || 'string'}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            this.props.activeField(selectedValue);
                                                            this.setState(prevState => ({
                                                                advance_datasource: [{
                                                                    ...prevState.advance_datasource[0],
                                                                    datasource_template: selectedValue
                                                                }]
                                                            }));
                                                        }}
                                                    >
                                                        <option value={'string'}>-Select Data Source-</option>
                                                        {this.props.allFormList?.data?.map((row, index) => (
                                                            <option value={row.id} key={index}>{row.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div className='m-3'>
                                                    <h6>Response Data Field</h6>
                                                    <Form.Select
                                                        defaultValue={this.props.list?.advance_datasource_detail[0]?.response || ''}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            this.setState(prevState => ({
                                                                advance_datasource: [{
                                                                    ...prevState.advance_datasource[0],
                                                                    response: selectedValue
                                                                }]
                                                            }));
                                                        }}
                                                    >
                                                        <option value="">-Select Response Data Field-</option>
                                                        {this.props.useTemp?.map((field, index) => (
                                                            <option value={field.id} key={index}>{field.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </Col>
                                            {/* <Col md={12}>
    <div className='m-3'>
        <h6>Select Data Source</h6>
        <Form.Select
            defaultValue={this.state.advance_datasource[0]?.datasource_template || 'string'}
            onChange={(e) => {
                const selectedValue = e.target.value;
                this.props.activeField(selectedValue);
                this.setState(prevState => ({
                    advance_datasource: [{
                        ...prevState.advance_datasource[0],
                        datasource_template: selectedValue
                    }]
                }));
            }}
        >
            <option value="string">-Select Data Source-</option>
            {this.props.allFormList?.data?.map((row, index) => (
                <option value={row.id} key={index}>{row.label}</option>
            ))}
        </Form.Select>
    </div>
</Col>

<Col md={12}>
    <div className='m-3'>
        <h6>Response Data Field</h6>
        <Form.Select
            value={this.state.advance_datasource[0]?.response || ''}
            onChange={(e) => {
                const selectedValue = e.target.value;
                this.setState(prevState => ({
                    advance_datasource: [{
                        ...prevState.advance_datasource[0],
                        response: selectedValue
                    }]
                }));
            }}
        >
            <option value="string">-Select Response Data Field-</option>
            {this.props.useTemp?.map((field, index) => (
                <option value={field.id} key={index}>{field.label}</option>
            ))}
        </Form.Select>
    </div>
</Col> */}
                                            <Col md={12}>
                                                <div className='m-3'>
                                                    <h6>Filter Data Field</h6>
                                                    <Form.Select
                                                        defaultValue={this.props.list?.advance_datasource_detail[0]?.filter || ''}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            this.setState(prevState => ({
                                                                advance_datasource: [{
                                                                    ...prevState.advance_datasource[0],
                                                                    filter: selectedValue
                                                                }]
                                                            }));
                                                        }}
                                                    >
                                                        <option value="">-Select Filter Data Field-</option>
                                                        {this.props.useTemp?.map((field, index) => (
                                                            <option value={field.id} key={index}>{field.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div className='m-3'>
                                                    <h6>Filter Value</h6>
                                                    <Form.Select
                                                        defaultValue={this.props.list?.advance_datasource_detail[0]?.value || 'string'}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            this.setState(prevState => ({
                                                                advance_datasource: [{
                                                                    ...prevState.advance_datasource[0],
                                                                    value: selectedValue
                                                                }]
                                                            }));
                                                        }}
                                                    >
                                                        <option value={'string'}>-Select Filter Value-</option>
                                                        {this.props.allFieldDetails?.map((row, index) => (
                                                            <option value={row.id} key={index}>{row.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <Button variant="secondary" style={{ float: "right", marginRight: "15px" }} onClick={this.handleAddClick}>
                                                    Add
                                                </Button>
                                            </Col>

                                            {/* Dynamic Filters */}
                                            { }
                                            {this.state.filters.map((filter, index) => (
                                                <React.Fragment key={index}>
                                                    <Col md={12}>
                                                        <div className="m-3">
                                                            <h6>Filter Data Field {index + 1}</h6>
                                                            <Form.Select

                                                                value={filter.filter}
                                                                onChange={(e) => this.handleFilterChange(index, 'filter', e.target.value)}
                                                            >
                                                                <option value="">-Select Filter Data Field-</option>
                                                                {this.props.useTemp?.map((field, idx) => (
                                                                    <option value={field.id} key={idx}>{field.label}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </Col>

                                                    <Col md={12}>
                                                        <div className="m-3">
                                                            <h6>Filter Value {index + 1}</h6>
                                                            <Form.Select
                                                                value={filter.value}
                                                                onChange={(e) => this.handleFilterChange(index, 'value', e.target.value)}
                                                            >
                                                                <option value="string">-Select Filter Value-</option>
                                                                {this.props.allFieldDetails?.map((row, idx) => (
                                                                    <option value={row.id} key={idx}>{row.label}</option>
                                                                ))}
                                                            </Form.Select>
                                                        </div>
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                        </Row>
                                    }

                                </div>


                            }
                        </div>
                }


                <Row className='d-flex justify-content-center mt-4' >
                    <Col md="11">
                        <Button style={{ width: "100%" }} onClick={() => this.onSubmit()}>Update</Button>
                    </Col>
                </Row>

                <Modal show={this.state.showModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set API</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <h6>Enter API</h6>
                        <Form.Control type="text" placeholder={'Enter API'} style={{ fontSize: 15 }}
                            onChange={(event) => {
                                var headers = [...this.state.headers]
                                headers[this.state.currentIndex].api = event.target.value
                                this.setState({ headers: headers })
                            }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>Close</Button>
                        <Button variant="success" onClick={() => this.setState({ showModal: false })}>SET</Button>
                    </Modal.Footer>
                </Modal>
                <OcrRemoveModal modalText={"If you remove the OCR, it might delete some fields."} showModal={this.state.showDeleteModal} handleClose={() => this.setState({ showDeleteModal: false, ocr_view: this.props.list?.ocr_view })}
                    deleteAction={(status) => {
                        this.setState({ ocr_view: status, showDeleteModal: false });
                        if (status == false) {
                            this.props.callBack();
                        }
                    }}
                />
            </div>
        )
    }
}
