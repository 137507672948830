import React, { useState } from "react";
import "./application.css";
import { Link } from "react-router-dom";
import features_dmsintegration from "../../../Images/features-dms-integration.jpg";

import CustomerFeedbackSurveys from "../../../Images/Customer Feedback Surveys.png";
import EventRegistrations from "../../../Images/Event Registrations.png";
import EcommerceOrderForms from "../../../Images/E-commerce Order Forms.png";
import HealthcarePatientIntake from "../../../Images/Healthcare Patient Intake.png";
import JobApplicationForms from "../../../Images/Job Application Forms.png";
import EducationalQuizzes from "../../../Images/Educational Quizzes and Exams.png";
import ContactLeadGeneration from "../../../Images/Contact and Lead Generation Forms.png";
import VolunteerSignupForms from "../../../Images/Volunteer Sign-up Forms.png";
export default function ApplicationArea() {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <section style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="sec-title text-center">
            <h2>Popular Use Cases</h2>
            <p>
              No matter your industry or need, GigaForms makes it easy to
              collect, organize, and manage data with customizable forms that
              fit your workflow.
            </p>
          </div>
          <div className="container">
            <div className="" style={{ boxShadow: "0 2px 37px 0 #0000001a" }}>
              <div className="row">
                <div className="col-md-4">
                  <div className="application-tab-container">
                    <ul>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 0 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(0)}
                        >
                          Customer Feedback Surveys
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 1 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(1)}
                        >
                          Event Registrations
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 2 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(2)}
                        >
                          E-commerce Order Forms
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 3 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(3)}
                        >
                          Healthcare Patient Intake
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 4 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(4)}
                        >
                          Job Application Forms
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 5 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(5)}
                        >
                          Educational Quizzes and Exams
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 6 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(6)}
                        >
                          Contact and Lead Generation Forms
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={`a-application__tabs-label ${
                            activeIndex === 7 ? "active" : ""
                          }`}
                          onClick={() => handleBoxClick(7)}
                        >
                          Volunteer Sign-up Forms
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-7">
                  <div
                    className={`application-content-div ${
                      activeIndex === 0 ? "active" : ""
                    }`}
                  >
                    <img
                      src={CustomerFeedbackSurveys}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Gather honest opinions from your customers to enhance your
                      products or services with valuable insights.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                  >
                    <img
                      src={EventRegistrations}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Facilitate a smooth sign-up process for events with
                      user-friendly forms that collect attendee details in
                      minutes.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                  >
                    <img
                      src={EcommerceOrderForms}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Simplify online shopping experiences by streamlining
                      orders and payments through quick and easy checkout forms.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 3 ? "active" : ""
                    }`}
                  >
                    <img
                      src={HealthcarePatientIntake}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Efficiently and securely collect patient information
                      before appointments, minimizing paperwork.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 4 ? "active" : ""
                    }`}
                  >
                    <img
                      src={JobApplicationForms}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Organize and simplify the hiring process by consolidating
                      structured job applications in one accessible location.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 5 ? "active" : ""
                    }`}
                  >
                    <img
                      src={EducationalQuizzes}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Develop engaging quizzes and assessments that offer
                      instant grading and feedback.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 6 ? "active" : ""
                    }`}
                  >
                    <img
                      src={ContactLeadGeneration}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Capture essential details from potential customers to
                      enhance your business growth with high-converting forms.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                  <div
                    className={`application-content-div ${
                      activeIndex === 7 ? "active" : ""
                    }`}
                  >
                    <img
                      src={VolunteerSignupForms}
                      alt="img"
                      style={{ height: "280px", width: "100%" }}
                    />
                    <p className="py-2">
                      Streamline the organization of volunteers with forms that
                      easily collect their availability and preferences.
                      efforts.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    >
                      <Link
                        to="/"
                        class="learn-more-btn learn-more-border ripple-effect aos-init aos-animate"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div class="circle"></div>
                        <p class="btn-text">Read More</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sec-title text-center "></div>
          <div className=" text-center ">
            <p>
              With ready-to-use templates, smart automation, and an intuitive
              builder, GigaForms helps businesses, educators, and organizations
              save time, stay organized, and make better decisions
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
