import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import React, { Component } from 'react'
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Switch from "./Switch";
import Dropdown from "./Dropdown";
import My_Button from "./Button";
import ImageField from "./ImageField";
import File from "./File";
import Signature from "./Signature"
import Table from "./Table";
import MultiChoiceOptions from "./multipleChoice";


export default class FieldHandler extends Component {
    constructor(props) {
        super(props)

        this.state = {
            myValue: null
        }
    }

    handleSave = () => {
        const dataUrl = this.state.sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        // Save the dataUrl as needed
        this.setState({ signatureData: dataUrl });
        this.setState({ SignatureModal: false })
    };


    onFileHandle = (type, data, value) => {
        switch (type) {
            case 'heading':
                return (
                    <Row style={{ width: "100%" }} >

                    </Row>
                )
            case 'text':
                return (
                    <Row style={{ width: "100%" }} >
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}> {data.label} </h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} size="md" type="text" disabled={this.props.editable} placeholder={data.placeholder} required={data.mandatory} onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                        />
                    </Row>
                )
            case 'textarea':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}> {data.label}</h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} disabled={this.props.editable} type="text" as="textarea" rows={3} placeholder={data.placeholder} required={data.mandatory} onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })}
                        // onBlur={(data) => this.props.onBlur(data.target.value)}
                        />
                    </Row>
                )
            case 'number':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} type="number" required={data.mandatory} disabled={this.props.editable} placeholder={data.placeholder} onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })}
                            onBlur={(data) => this.props.onBlur(data.target.value)} />
                    </Row>
                )
            case 'checkbox':
                return (
                    <Row style={{ width: "100%", marginLeft: -12 }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <Checkbox
                            fieldData={data}
                            value={this.props.value}
                            disabled={this.props.editable}
                            title={data.tooltip}
                            onChange={(event) => this.props.onChange({ value: event, field: data.id })} />
                    </Row>
                )
            case 'radio':
                return (
                    <Row style={{ width: "100%", marginLeft: -12 }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>

                        <Radio
                            value={this.props.value}
                            data={data}
                            required={data.mandatory}
                            editable={!this.props.editable}
                            onChange={(event) => this.props.onChange({ value: event, field: data.id })} />

                    </Row>

                )
            case 'date':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}> {data.label}</h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} required={data.mandatory} type="date" disabled={this.props.editable} placeholder={data.placeholder} onBlur={(data) => this.props.onBlur(data.target.value)}

                            onChange={(e) => {
                                const newValue = e.target.value; // Get the new value
                                const fieldId = data.id; // Get the field ID

                                // Store the object in localStorage
                                if (data.event?.length > 0) {
                                    try {
                                        localStorage.setItem("datedropdown", JSON.stringify({ value: newValue, field: fieldId }));
                                        console.log("Stored in localStorage:", localStorage.getItem("datedropdown"));
                                    } catch (error) {
                                        console.error("Error storing in localStorage:", error);
                                    }
                                }


                                // Trigger the onChange prop
                                this.props.onChange({ value: newValue, field: fieldId });
                            }}


                        />
                    </Row>
                )
            case 'datetime':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}> {data.label} </h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} required={data.mandatory} type="datetime-local" disabled={this.props.editable}
                            onBlur={(data) => this.props.onBlur(data.target.value)} placeholder={data.placeholder} onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })} />
                    </Row>
                )
            case 'email':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} required={data.mandatory} disabled={this.props.editable} size="md"
                            type="email" placeholder={data.placeholder}
                            onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })}
                            onBlur={(data) => this.props.onBlur(data.target.value)}
                        />
                    </Row>
                )
            case 'password':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}> {data.label} </h6>
                        </div>
                        <Form.Control defaultValue={this.props.value} required={data.mandatory} type="password" disabled={this.props.editable} placeholder={data.placeholder} onChange={(e) => this.props.onChange({ value: e.target.value, field: data.id })}
                            onBlur={(data) => this.props.onBlur(data.target.value)} />
                    </Row>
                )
            case 'dropdown':
                return (
                    <Row style={{ width: "100%" }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <Dropdown
                            fieldData={data}
                            finaldropdownData={this.props?.finalDropdownData}
                            required={data.mandatory}
                            editable={this.props.editable}
                            selected={this.props.value}
                            title={data.tooltip}
                            ShareToken={this.props.Sharetoken}
                            onChange={(selectedData) => this.props.onChange({ value: selectedData, field: data.id })}
                            optionData={(dta) => {
                                // console.log('dta', dta)
                                this.props.alldropdown(dta)
                            }}
                        />
                    </Row>
                )
            case 'button':
                return (
                    <My_Button required={data.mandatory} data={data} editable={this.props.editable} />
                )
            case 'image':
                return (
                    <div style={{ width: "100%", marginLeft: -12 }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <File
                            disabled={this.props.editable}
                            fileType={'image/png, image/gif, image/jpeg'}
                            value={this.props.value}
                            title={data.tooltip}
                            fieldData={data}
                            onOCR_View={(id, mydata) => { this.props.onChange({ value: mydata, field: id }); }}
                            ShareToken={this.props.Sharetoken}
                            onChange={(selectedData) =>
                                this.props.onChange({ value: selectedData, field: data.id })
                            }
                        />
                    </div>
                )
            case 'file':
                return (
                    <div style={{ width: "100%", marginLeft: -12 }}>
                        <div className="d-flex flex-row" >
                            {
                                data.mandatory &&
                                <span style={{ color: "red", fontSize: 17 }}>*</span>
                            }
                            <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        </div>
                        <File
                            disabled={this.props.editable}
                            fileType={'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'}
                            value={this.props.value}
                            title={data.tooltip}
                            ShareToken={this.props.Sharetoken}
                            fieldData={data}
                            onOCR_View={(id, mydata) => {
                                this.props.onChange({ value: mydata, field: id });
                            }}
                            onChange={(selectedData) => {
                                this.props.onChange({ value: selectedData, field: data.id });
                            }
                            }
                        />
                    </div>
                )
            case 'signature':
                return (
                    <div style={{ width: "100%", marginLeft: -12 }}>
                        <Signature
                            disabled={this.props.editable}
                            value={this.props.value}
                            data={this.props.data}
                            title={data.tooltip}
                            ShareToken={this.props.Sharetoken}
                            onChange={(selectedData) =>
                                this.props.onChange({ value: selectedData, field: data.id })
                            }
                        /></div>
                )
            case 'table':
                return (
                    <div style={{ width: "100%", marginLeft: -12 }}>
                        <h6 title={data.tooltip} className="d-flex flex-row" style={{ fontWeight: "600", fontSize: 17 }}>{data.label} </h6>
                        <Table
                            disabled={this.props.editable}
                            data={this.props.data}
                            defaultValue={this.props.value}
                            onChange={(selectedData) =>
                                this.props.onChange({ value: selectedData, field: data.id })
                            }
                        />
                    </div>
                )
                case 'multiple_choice':
                    
                    return (
                       
                        <Row style={{ width: "100%", marginLeft: -12 }}>
                            <div className="d-flex flex-row">
                                {data.mandatory && <span style={{ color: "red", fontSize: 17 }}>*</span>}
                                <h6 style={{ fontWeight: "600", fontSize: 17 }}>{data.label}</h6>
                               
                            </div>
                            <MultiChoiceOptions
                            disabled={this.props.editable}
                            data={this.props.data}
                            defaultValue={this.props.value}
                            onChange={(selectedData) =>
                                this.props.onChange({ value: selectedData, field: data.id })
                            }
                        />
                        </Row>
                    );
            default:
                break;
        }
    }


    render() {
       
        return (
            <>
                <div style={{ width: "100%" }}>
                    {this.onFileHandle(this.props.type, this.props.data, this.props.value)}
                </div>
            </>
        )
    }
}
