import React from "react";
import security from "../../../Images/icons/security.png";
import Inbuilttemplates from "../../../Images/icons/In-built-templates.png";
import automationworkflowmanagement from "../../../Images/icons/automation-&-workflow-management.png";
import easyuse from "../../../Images/icons/easy-use.png";
import integration from "../../../Images/icons/integration.png";
import investment from "../../../Images/icons/investment.png";
import mobileapplication from "../../../Images/icons/mobile-application.png";
import omnichannel from "../../../Images/icons/omnichannel.png";
import realtimedatacollection from "../../../Images/icons/real-time-data-collection.png";
import scalable from "../../../Images/icons/scalable.png";

const ContentWithImageList = () => {
  // Define an array of items with images and titles
  const items = [
    { id: 1, title: "Ease of Use ", icon: easyuse },
    {
      id: 2,
      title: "In-built templates",
      icon: Inbuilttemplates,
    },
    {
      id: 3,
      title: "Integration Capabilities ",
      icon: integration,
    },
    { id: 4, title: "Data Security", icon: security },
    {
      id: 5,
      title: "Automation & Workflow Management",
      icon: automationworkflowmanagement,
    },
    {
      id: 6,
      title: "Real-Time Data Collection",
      icon: realtimedatacollection,
    },
    { id: 7, title: "Scalability", icon: scalable },
    {
      id: 8,
      title: "Mobile Application",
      icon: mobileapplication,
    },
    {
      id: 9,
      title: "Cost-Effective Solution ",
      icon: investment,
    },
    {
      id: 10,
      title: "Seamless & Powerful Form Building",
      icon: omnichannel,
    },
  ];

  // Split the items into two halves
  const topItems = items.slice(0, 5);
  const bottomItems = items.slice(5);

  return (
    <section className="">
      <div className="container">
        <div>
          <h2 className="text-center mt-4" style={{ paddingBottom: "20px" }}>
            Why Choose GigaForms?
          </h2>

          {/* Top items */}
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "20px", // Margin Top for the top items list
              marginBottom: "20px", // Margin Bottom for the top items list
            }}
          >
            {topItems.map((item) => (
              <div
                key={item.id}
                style={{
                  textAlign: "center",
                  flex: "1 1 150px",
                  border: "1px solid #ddd", // Border around the item
                  padding: "10px",
                  borderRadius: "8px", // Rounded corners
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow
                  transition: "transform 0.3s ease", // Smooth hover effect
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "#fff"; // Change background color on hover
                  e.currentTarget.style.transform = "translateY(-10px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Reset background color when hover ends
                  e.currentTarget.style.transform = "translateY(0)";
                }}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                />
                <div>{item.title}</div>
              </div>
            ))}
          </div>

          {/* Bottom items */}
          <div
            style={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
              marginTop: "20px", // Margin Top for the bottom items list
              marginBottom: "20px", // Margin Bottom for the bottom items list
            }}
          >
            {bottomItems.map((item) => (
              <div
                key={item.id}
                style={{
                  textAlign: "center",
                  flex: "1 1 150px",
                  border: "1px solid #ddd", // Border around the item
                  padding: "10px",
                  borderRadius: "8px", // Rounded corners
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Box shadow
                  transition: "transform 0.3s ease", // Smooth hover effect
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "white"; // Change background color on hover
                  e.currentTarget.style.transform = "translateY(-10px)";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Reset background color when hover ends
                  e.currentTarget.style.transform = "translateY(0)";
                }}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{
                    width: "40px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                />
                <div>{item.title}</div>
              </div>
            ))}
          </div>

          {/* Mobile-specific styles */}
          <style>{`
        @media (max-width: 768px) {
          div {
            display: block;
            text-align: center;
          }
          div > div {
            margin-bottom: 10px;
          }
        }
      `}</style>
        </div>
      </div>
    </section>
  );
};

export default ContentWithImageList;
