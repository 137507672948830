import React, { Component } from "react";
import { Form } from "react-bootstrap";

export default class MultiChoiceOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table_data: {
        options: [],
      },
    };
  }

  componentDidMount() {
    this.updateTableData(this.props.data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateTableData(this.props.data);
    }
  }

  updateTableData = (data) => {
    if (data?.table_data) {
      try {
        const tableData = Array.isArray(data.table_data)
          ? data.table_data
          : JSON.parse(data.table_data);

        const updatedOptions = tableData.map((item) => ({
          type: "text",
          name: item.name, // ✅ Ensure 'name' is used
          value: item.name, // ✅ Bind correct value
          selected: item.selected || false, // Default to false if undefined
        }));

        this.setState({
          table_data: {
            options: updatedOptions,
          },
        });
      } catch (error) {
        console.error("Error parsing table_data:", error);
      }
    }
  };

  handleMultiChoiceChange = (selectedValue) => {
    this.setState(
      (prevState) => {
        const updatedOptions = prevState.table_data.options.map((option) =>
          option.value === selectedValue
            ? { ...option, selected: !option.selected } // ✅ Toggle selection
            : option
        );

        return {
          table_data: {
            options: updatedOptions,
          },
        };
      },
      () => {
        // ✅ Send updated options to parent
        this.props.onChange(this.state.table_data.options);
      }
    );
  };

  render() {
    const { table_data } = this.state;
    const options = table_data.options || [];
    console.log("Props Data:", this.props.data);

    return (
      <div>
        {options.map((option, index) => (
          <Form.Check
            style={{ display: "flex" }}
            key={index}
            type="checkbox"
            label={option.name}
            checked={option.selected} // ✅ Bind state properly
            onChange={() => this.handleMultiChoiceChange(option.value)} // ✅ Use 'value' instead of 'selected'
          />
        ))}
      </div>
    );
  }
}
